import React from 'react';
import { Container, Typography} from '@material-ui/core';
import Layout from '../components/layout';
import Link from 'gatsby-link';
import SEO from '../components/seo'

const termsPage = () => (
    <Layout location="">
        <SEO title='Terms of Service' description='Blix Terms of Service' />
        <Container fixed style={{paddingTop: '150px', paddingBottom: '50px'}}>
            <Typography color='textPrimary'>TERMS OF SERVICE</Typography>
            <Typography color='textSecondary'>
            <div id='privacy2'>
            <div className='col-12'>
                <p>
                
                These Terms of Service (these “Terms”), and any terms or policies we refer to and include in these Terms, explain how you can use the Blix and Blix Premium mobile apps, websites, services and software and any related website owned or operated by Blix Inc. (collectively the “Blix Service”), provided by Blix Inc. and its subsidiaries  (“Blix”, “we” or “our”) and form a legally binding agreement, so please read these Terms before you get started. They apply to all visitors, users and others who access the Blix Service (“Users”).
                </p>
                <p>

                By using the Blix Service, you agree as an individual User to be bound by these Terms and to the collection and use of your information as spelled out in our <Link style={{color: '#1AA3FF'}} to='/privacy/'>Privacy Policy</Link>. If you are using Blix or Blix Premium on behalf of an employer, organization, business or other entity you agree to these Terms on behalf of the employer or entity represent that  (i) you have the full legal authority to bind your employer or other entity to these Terms, and (ii) you have read and understand these Terms, and you agree to these Terms on behalf of your employer or other entity and (iii) these terms will bind your employer or other entity.  If you acting as a representative of an employer or other entity, “you” and “your” refers to that employer or entity.
                </p>
                <p>
                NOTE: YOU ARE DEEMED TO BE AN AUTHORIZED REPRESENTATIVE OF YOUR EMPLOYER OR OTHER ENTITY: (i) IF YOU ARE USING YOUR EMPLOYER’S OR AN ENTITY’S EMAIL ADDRESS IN REGISTERING FOR THE BLIX SERVICE; AND/OR (ii) YOU ARE THE “ADMINISTRATOR” FOR YOUR ORGANIZATION, AS DEFINED BELOW.
                </p>
                <p>
                YOU ACKNOWLEDGE THAT THESE TERMS AE BINDING ON YOU INDIVIDUALLY OR YOUR EMPLOYER OR OTHER ENTITY, AND YOU WILL SIGNIFY THIS ACKNOWLEDGEMENT BY EITHER (i) CHECKING THE BOX PROVIDED BY BLIX RECORDING YOUR ACCEPTANCE OF THESE TERMS; OR (ii) REGISTERING FOR, ACCESSING OR USING THE BLIX SERVICE.
                </p>
                <p>
                IF YOU DO NOT HAVE THE AUTHORITY TO BIND YOUR EMPLOYER OR OTHER ENTITY, OR DO NOT AGREE TO BE BOUND BY THESE TERMS, <strong>DO NOT ACCEPT THESE TERMS AND DO NOT USE THE BLIX SERVICE.</strong>
                </p>
            </div>
            <div className='col-12'>
                <h3>Using the Blix Service</h3>
                <p>
                The Blix Service is a combined email and messaging application and Cloud-based service that helps you manage your email account, calendar and other content from your device. You can add your Gmail, Google Suite, Office365, Dovecot, Exchange and other mail accounts or content.  The Blix Premium Service offers additional features for a business or other entity and it is available only as a paid subscription.                </p>
                <p>
                When registering for the Blix Service, you agree to provide accurate information about yourself and/or your employer. You also understand that you are responsible for the activity in the Blix Service and for safeguarding any passwords that you use. You are not allowed to access another User’s account without their permission.                </p>
                <p>
                We expect and trust that you will use the Blix Service responsibly – which means, for example, you agree not to interfere with the Blix Services or functions and features or try to access them using a method other than through the interface and the instructions that we provide. You also agree not to:                </p>
                    <ul>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> copy, distribute, or disclose any part of the Blix Service in any medium.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> probe, scan, or test the vulnerability of any system or network.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> breach or otherwise circumvent any security or authentication measures.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> interfere with or disrupt any user, host, or network, for example: do not send a       virus, spam, mail-bomb or attempt to overload our systems to deny service as to any part of the Blix Service.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> plant malware or otherwise use the Blix Service to distribute malware.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> send unsolicited communications, promotions or advertisements, or spam.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> send altered, deceptive or false source-identifying information, including “spoofing” or “phishing”.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> publish anything that is fraudulent, misleading, or infringes another’s rights.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} />  impersonate or misrepresent your affiliation with any person or entity.</span>
                        </li>
                        <li>
                            <span><span className='icon fa-circle' style={{fontSize: '10px'}} /> violate the law in any way, violate the privacy of others, harass or defame others (for example, sending illegal or defamatory emails with the Blix Service).</span>
                        </li>
                    </ul>
                    <p>
                    We may permanently or temporarily revoke or suspend your access to the Blix Service without notice and liability for any reason including your violation of these Terms. Upon termination for any reason or no reason, you will continue to be bound by the applicable Terms. 
                    </p>
                    <p>
                    We may modify, add or discontinue any feature, function or tool within the Blix Service at any time at our sole discretion.  However, in the event we make a material adverse change we will send you a notice via email or post a notice on the Blix site.By using the Blix Service, you also accept our Data Processing Agreement which governs the processing of personal data (as defined int the DPA) on a User’s behalf, where such personal data is subject to the General Data Protection Regulation of the EU.
                    </p>
            </div>
            <div className='col-12'>
                <h3>Legal Capacity, Children and Minors</h3>
                <p>If you access or use the Blix Service, you represent that you are over the age of consent in your country to form a binding contract.  If you are under the age of consent, you should only use the Blix Service if you have the legal consent of your parent or guardian for your use of the Blix Service. You should use the Blix Service only if you are fully able to understand and enter into and comply with these Terms. The registration or use of the Blix Service by anyone under 16 is strictly prohibited. We reserve the right to request proof of age at any time to verify your compliance with this term.</p>
            </div>
            <div className='col-12'>
                <h3>Usage</h3>
                <p>To register for the Blix Service for the first time, you will create a Blix account.  When you create a Blix account (“Account”) and are registering for the Blix Service either individually or on behalf of your employer or other entity, you will become a Blix user (“User”).  Note, for some purposes when registering on behalf of an employer, organization, business or other entity, it will be considered our customer (“Customer”). The first User of an account registered on an employer’s or other entity’s email address is automatically assigned as the Account Administrator (“Administrator”).  We may require you to provide information to be used to verify your identity and help us ensure the security of your Account.  If you or the Administrator lose access to the Account or request information about the Account, we may require verification information before restoring access or providing any requested information.</p>
                <p>The Administrator my assign or add other Users as Administrators who will have the same privileges as the first Administrator to control the use of the Blix Service, including (i) control your use, and all other Users use, of the Account; (ii) to purchase, upgrade or downgrade the Blix Service; (iii) create, monitor or modify a User’s permitted uses and actions; and (iv) manage the User’s access to the Blix Service or control, remove or otherwise modify the User’s activities on the Blix Service. </p>
                <p>Each User is responsible for understanding the settings, functions and controls for the Blix Service.  The Administrator is responsible for who becomes a User and the privileges of each User on the Blix Service. </p>
                <p>The Blix Service offers a powerful set of tools for an individual or business to organize emails and content including: the Share Mail function uploads designated emails to our proprietary Cloud for distribution or storage; the Chat Service allows Users to upload, download and store content, including but not limited to text, graphics, information, videos, audios and other digital data in our proprietary Cloud; and the Backup Settings function allows a User to back up a Users’ settings to our proprietary Cloud.</p>
                <p>The Blix Premium Service for businesses and other entities offers additional tools including: the Application allows an Administrator to connect its LDAP authentication software and Active Directory servers and import its lists of email users (including names, email addresses and profile photos) and email groups; a User can employ the Blix proprietary API to connect to third-party apps (Note: Each User remains subject to the Terms of Use of these apps); and Users can create a Blix Messaging Bridge Widget for use on a User’s website, which will cause any visitor’s email or message to be saved on our proprietary Cloud.</p>
                <p>The Blix Service allows Users to connect their email account, so we can check, process and deliver email or other content on the User’s behalf. By using the Blix Service, you allow Blix to help manage content, information, emails, and attachments in those emails. By using the Blix Service, you give Blix. permission to host or share your data pursuant to your instructions through the Blix Service. These functions include receiving and sending emails or other content. It also includes creating backups of your recent and deferred data to ensure it is safe and always accessible to you. By using the Blix Service, you give Blix permission to extend such permission to trusted third parties that help us to run the Blix Service (like Amazon AWS, which hosts our Cloud). These exceptions are outlined in our <Link style={{color: '#1AA3FF'}} to='/privacy/'>Privacy Policy</Link>, which explains how Blix collects and uses your information generally.</p>
                <p>The Blix Service does not include the following: (i) the Blix Instant Push proxy does not store any emails; (ii) while information is stored pursuant to the Blix <Link style={{color: '#1AA3FF'}} to='/privacy/'>Privacy Policy</Link>, Blix does not sell the User’s data for any purpose; and (iii) the Calendar Events feature does not store events on our Cloud.</p>
            </div>
            <div className='col-12'>
                <h3>Our Proprietary Rights</h3>
                <p>We retain our right title and interest in the Blix Service, and all the Intellectual Property that makes up the Blix Service, including, without limitation, the software, design, text, graphics, logos, patents, trademarks, service marks, copyrights (“Our IP”), and all intellectual property rights related to Our IP. Subject to these Terms, we give you a non-exclusive, limited, non-transferable, freely revocable license to utilize the Blix Service for your business or personal, non-commercial use only (i.e. you cannot re-sell the Blix Service). We reserve all rights not expressly granted in these Terms in the Blix Service and Our IP. These Terms do not provide you with any rights to use our trademarks, logos, domain names, or other brand features. Use of Our IP for any purpose not expressly permitted by these Terms is strictly prohibited.</p>
                <p>You may choose to give us feedback, comments, suggestions, improvements or other ideas about the Blix Service (“Ideas”). By submitting an Idea to Blix, you agree that you are doing so to benefit all Users and not for personal profit.  As such, you irrevocably grant to Blix a royalty-free license to access, use, copy, reproduce, transmit or display the Ideas for any business or reason including promoting Blix or any other lawful purpose.  As to any Ideas submitted to Blix, you represent that the Ideas are original or that you have any intellectual property rights necessary to make the submission without restriction.  Further, you waive any past, present or future moral rights you may have in the Ideas. </p>
            </div>
            <div className='col-12'>
                <h3>User Data</h3>
                <p>You are responsible and liable for your data and what you otherwise copy, share, upload, download, attach, send, receive, record or otherwise use while using the Blix Service. You are responsible and liable for anything you copy, share, upload, download, attach, send, receive, record or otherwise do while using the Blix Service, and you represent that you have all necessary rights to use your data on or through the Blix Service.</p>
                <p>We will respond to notices of alleged copyright infringement according to the process set out in the U.S. Digital Millennium Copyright Act. Such notices should be reported as outlined in our DMCA Policy. We reserve the right to suspend your account, delete or disable content alleged to be infringing and/or terminate the account of a repeat infringer.</p>
            </div>
            <div className='col-12'>
                <h3>Privacy and Security</h3>
                <p>Your privacy is extremely important to us – we care about the integrity and security of your personal information. You can have the full insight on how we collect, use, process, transfer, protect, retain and maintain personal information in our <Link style={{color: '#1AA3FF'}} to='/privacy/'>Privacy Policy</Link>, which is incorporated into these Terms of Service and form an integral part hereof.</p>
                <p>
                    <strong>Software from Blix Inc: </strong> Some uses of the Blix Service require that you download a client software package (“Application”). To use the Blix Service, you must have a device that is compatible, so we cannot guarantee that the Application will work on your device. We grant you a limited, nonexclusive, nontransferable, revocable license to use the Application, solely to access the Blix Service. Your license to use the Application is automatically revoked if you violate these Terms. You may not copy, modify, distribute, sell, or lease any part of the Blix Service or the Application, nor may you reverse engineer or attempt to extract the source code of that software (unless laws prohibit those restrictions, or you have our written permission).
                </p>
                <p>
                    <strong>Updates:</strong> From time to time, we may issue upgraded versions of the Application. You agree that these Terms will apply to all such upgrades. Any third-party code that may be incorporated in the Application is covered by the applicable open source or third-party license EULA, if any, authorizing use of such code. The foregoing license grant is not a sale of the Application or a copy of the Application, and Blix, and our partners and suppliers, retain all rights and interest in the Application. Any attempt by you to transfer any of the rights, duties or obligations hereunder, except as expressly provided for in these Terms, is void. We reserve all rights not expressly granted under these Terms.
                </p>
                <p>
                    <strong>Premium Features and Premium Service:</strong> While the standard Blix Services is free, additional data charges may apply through your mobile service provider. Additional fees will apply for the Blix Premium Service and for the use of any Premium Features.
                </p>
                <p>The Blix Premium Service and some Premium Features are available on a subscription basis. Subscriptions may be monthly or annually. Payments for such subscriptions will be charged to your credit card account at the confirmation of the purchase. The payments may be processed by third parties acting on our behalf. Your subscription will automatically renew for the same price and duration period as the original subscription package chosen by you, unless you turn off the auto-renew at any time before the end of the subscription period. Your account will be charged at the end of the subscription period at the cost of the chosen service or feature. You may manage subscriptions and turn off auto-renewal by going to the user’s account settings after the purchase. You can cancel the subscription at any time and the cancellation will take effect after the end of the last day of that subscription period.</p>
            </div>
            <div className='col-12'>
                <h3>Modifying and Terminating Service</h3>
                <p>We are constantly changing and improving  the Blix Service. We may add or remove features, and while we will do our best to give you advance notice about any major changes, you understand that we may stop, suspend, or change the Blix Service at any time without prior notice. We may also remove content from the Blix Service at our discretion.</p>
                <p>From time-to-time we may offer pre-release versions of the Blix Service, or pre-release versions of specific functions and features. Such pre-release versions are under development and we will use reasonable efforts to identify such services as Alpha or Beta versions.  As such, they may be incomplete or inoperable, may contain bugs, suffer disruptions or may not operate as intended and designed.  These Alpha and Beta versions are offered to Users on an “as is” basis without any warranty or representation whatsoever. User agrees to provide appropriate feedback to Blix as to the operation of the Alpha and Beta versions so that the Blix Service can be improved for all Users.</p>
            </div>
            <div className='col-12'>
                <h3>Confidential Information</h3>
                <p>In connection with these Terms, each party (“Disclosing Party”) may disclose non-public business, product, technology and marketing information, including without limitation, customers lists and information, know-how, software and any other non-public information that is either identified as such or should reasonably be understood to be confidential given the nature of the information and the circumstances of disclosure (the “Confidential Information”) to the other party (“Receiving Party”).  Confidential Information includes (i) User’s emails and data, and (ii) the Blix Service, inclusive of its underlying technology, the functions, features, graphic presentation and their respective performance information, as well as any data, reports and materials we provided to you in connection with your use of the Blix Service.  Confidential Information does not include information that (a) is or becomes generally available to the public without breach of any obligation owed to the Disclosing Party; (b) was known to the Receiving Party prior to its disclosure by the Disclosing Party; (c) is received from a third party without breach of any obligation owed to the Disclosing Party; or (d) was independently developed by the Receiving Party without any use or reference to the Confidential Information.  </p>
                <p>The Receiving Party will (i) take all reasonable measures to prevent the unauthorized disclosure or use of Confidential Information, and limit access to those employees, affiliates, service providers and agents, on a need to know basis and who are bound by confidentiality obligations at least as restrictive as those contained herein; and (ii) not use or disclose any Confidential Information to any third party, except as part of its performance under these Terms and as required to be disclosed to legal or financial advisors to the Receiving Party or in connection with a due diligence process that the Receiving Party is undergoing, provided that any such disclosure shall be governed by confidentiality obligations at least as restrictive as those contained herein. </p>
                <p>Confidential Information may also be disclosed pursuant to the order of a court, administrative agency or other governmental body; provided, however, that to the extent legally permissible, the Receiving Party shall make best efforts to provide prompt written notice of such court order or requirement to the Disclosing Party to enable the Disclosing Party to seek a protective order or otherwise prevent or restrict such disclosure.</p>
            </div>
            <div className='col-12'>
                <h3>General Legal Terms</h3>
                <h5>User’s Indemnity</h5>
                <p>You agree to defend, indemnify and hold harmless Blix and its subsidiaries, agents, licensors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney’s fees) arising from: (i) your use of and access to the Blix Service, including any data or content transmitted or received by you; (ii) your violation of any term of these Terms; (iii) your violation of any third-party right, including without limitation any right of privacy or intellectual property rights; (iv) your violation of any applicable law, rule or regulation; (v) any claim for damages that arises as a result of any of your data or any content that is submitted via your account; or (vi) any other party’s access and use of the Blix Service with your unique username, password or other appropriate security code.</p>
            </div>
            <div className='col-12'>
                <h5>Blix Indemnity</h5>
                <p>Blix  agrees to defend User against any third-party claim alleging that User’s authorized use of the Blix Service infringes or constitutes misappropriation of any third party’s copyright, trademark or registered US patent (the “IP Claim”), and we will indemnify User and hold User harmless against any damages and costs finally awarded on such IP Claim by a court of competent jurisdiction or a settlement we previously agreed upon, including reasonable attorneys’ fees. Blix’s  indemnity obligations under this section shall not apply if: (i) the Blix Service was modified by User or any of its users or any third party, but solely to the extent the IP Claim would have been avoided (ii) if the Blix Service is used in combination with any other service, device, software or products; and/or (ii) any IP Claim arising or related to, the User’s Data. Without regard to the foregoing, if Blix  believes that the Blix Service, or any part thereof, may so infringe, then Blix  may in its sole discretion: (i) obtain (at no additional cost to you) the right to continue to use the Blix Service; (ii) replace or modify the allegedly infringing part of the Blix Service so that it becomes non-infringing while giving substantially equivalent performance; or (iii) if Blix  determines that the foregoing remedies are not reasonably available, then it may terminate part or all of the Blix Service.  In such an event, User shall receive a prorated refund of any subscription fees. THIS  STATES BLIX’S  SOLE AND ENTIRE LIABILITY AND YOUR EXCLUSIVE REMEDY, FOR ANY INTELLECTUAL PROPERTY INFRINGEMENT OR MISAPPROPRIATION CLAIM.</p>
            </div>
            <div className='col-12'>
                <h5>No Warranty</h5>
                <p>THE BLIX SERVICE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS. USE OF THE BLIX SERVICE AND THE APPLICATION IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE BLIX SERVICE AND THE APPLICATION IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, BLIX  AND ITS LICENSORS, DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR CORRECT; THAT THE BLIX SERVICE OR THE APPLICATION WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE BLIX SERVICE OR THE APPLICATION IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. Any content downloaded or otherwise obtained through the use of the Blix Service is downloaded at your own risk and you will be solely responsible for any damage to your computer system or mobile device or loss of data that results from such download or use of the service.</p>
            </div>
            <div className='col-12'>
                <h5>Limitation of Liability</h5>
                <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL BLIX, ITS AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, SUPPLIERS OR LICENSORS BE LIABLE FOR (I) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL (INCLUDING LOSS OF USE, DATA, BUSINESS, REVENUE OR PROFITS) DAMAGES, REGARDLESS OF LEGAL THEORY, WHETHER OR NOT BLIX HAS BEEN WARNED OF THE POSSIBILITY OF SUCH DAMAGES, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; (II) ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE GREATER OF $20 OR THE AMOUNTS PAID BY YOU TO BLIX  FOR THE PAST THREE MONTHS OF THE SERVICES IN QUESTION. Some states do not allow the types of limitations in this paragraph, so they may not apply to you. The Blix Service is controlled and operated from facilities in the United States and other countries. Blix makes no representations that the Blix Service is appropriate or available for use in other locations. Those who access or use the Blix Service from other jurisdictions do so at their own volition and risk and they are entirely responsible for compliance with all applicable United States and local laws and regulations, including but not limited to export and import regulations.</p>
            </div>
            <div className='col-12'>
                <h5>Notification Procedures and Changes to these Terms</h5>
                <p>Blix may provide notifications, whether such notifications are required by law or are for marketing or other business-related purposes, to you via email notice, written or hard copy notice, or through posting of such notice on our website, as determined by Blix in our sole discretion. Blix reserves the right to determine the form and means of providing notifications to our users, provided that you may opt out of certain means of notification as described in these Terms. Blix is not responsible for any automatic filtering you or your network provider may apply to email notifications we send to the email address you provide us. </p>
                <p>Blix may, in its sole discretion, modify or update these Terms from time to time, and so you should review this page periodically. When we change the Terms in a material manner, we will update the ‘last modified’ date at the bottom of this page. Your continued use of the Blix Service after any such change constitutes your acceptance of the new Terms of Service. If you do not agree to any of these terms or any future Terms of Service, do not use or access (or continue to access) the Blix Service.</p>
            </div>
            <div className='col-12'>
                <h5>Governing Law</h5>
                <p>These Terms and the use of the Blix Service and Application will be governed by the law of the State of New York, USA except for its conflicts of laws principles. You agree to submit to the exclusive personal jurisdiction of the federal and state courts located in New York City, NY, except for injunctive or other equitable relief which may be served in a court of competent jurisdiction as the case may be to prevent the actual or threatened infringement, misappropriation or violation of our copyrights, trademarks, trade secrets, patents, or other intellectual property or proprietary rights.</p>
            </div>
            <div className='col-12'>
                <h5>Dispute Resolution</h5>
                <p>For any dispute with Blix, you agree to first contact us at privacy@blix.net setting out in reasonable detail the nature of any claim or dispute.  We will respond to any claim or dispute with the goal of resolving it informally. If informal resolution is not successful, you agree to mediate the claim within 60 days of your notice to Blix using the services of the American Arbitration Association (“AAA”). In the unlikely event that Blix has not been able to resolve a dispute it has with you after attempting to do so by mediation, we each agree to resolve any claim, dispute, or controversy (excluding any Blix claims for injunctive or other equitable relief) arising out of or in connection with or relating to these Terms, or the breach or alleged breach thereof (collectively, “Claims”), by binding arbitration administered by the AAA under its Commercial Arbitration Rules and Supplementary Procedures for Consumer Related Disputes then in effect for the AAA, except as provided herein. The arbitration will be conducted in New York, NY, unless you and Blix agree otherwise. Any arbitration must be commenced by the filing of a Demand for Arbitration within one year after the party asserting the claim first knew, or reasonably should have known, of the actions or defaults giving rise to the claim.  Each party will be responsible for paying any AAA filing, administrative and arbitrator fees in accordance with AAA rules. The award rendered by the arbitrator shall include the costs of arbitration, reasonable attorneys’ fees and reasonable costs for expert and other witnesses, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. </p>
                <p>The arbitration will be a confidential proceeding.  Neither party will make any public announcement or public comment or release any publicity concerning the arbitration including the fact that the parties are in dispute, the existence of the arbitration or any decision or award of the arbitrator.  All claims must be brought in the parties’ individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding, and, unless we agree otherwise, the arbitrator may not consolidate more than one person’s claims. You agree that, by entering into this agreement, you and Blix are each waiving the right to a trial by jury or to participate in a class action.</p>
                <p>Nothing in this Section shall prevent either party from seeking injunctive or other equitable relief from the courts as necessary to prevent the actual or threatened infringement, misappropriation, or violation of that party’s data security, intellectual property rights, or other proprietary rights. </p>
            </div>
            <div className='col-12'>
                <h5>Force Majeure Event</h5>
                <p>Neither party will be held responsible for delays caused by acts beyond its control, such as acts of God or public enemies, utility or communications delays or failures not caused by such Party’s negligence or fault, accidents not caused by such party’s negligence or fault, labor disputes (a “Force Majeure Event”) Each party will use reasonable efforts to mitigate the effect and duration of such Force Majeure Event.</p>
            </div>
            <div className='col-12'>
                <h5>Legal Relationship; Waiver; Enforcement; and No Assignment</h5>
                <p>Blix and you are not legal partners or agents; instead, our relationship is that of independent contractors. These Terms create no third-party beneficiary rights. Blix’s  failure to enforce a provision is not a waiver of its right to do so later. If a provision is found unenforceable the remaining provisions of these Terms will remain in full effect and an enforceable term will be substituted reflecting our intent as closely as possible. You may not assign any of your rights in these Terms, without our prior written approval and any such attempt is void.  We may assign our rights and/or obligations under this agreement and transfer our ownership rights and title in the Blix Service to any third party without your consent or prior notice. </p>
            </div>
            <div className='col-12'>
                <h5>Entire Agreement</h5>
                <p>These Terms constitute the entire and exclusive agreement between you and Blix with respect to the Blix Service, and supersede and replace any other agreements, oral or written statements, website content, marketing materials, or other terms and conditions applicable to Blix. </p>
                <p>Please contact us at support@blix.net with any questions regarding these Terms</p>
                <p>Updated: August 15th, 2019</p>
            </div>
            </div>
        </Typography>
    </Container>
    </Layout>

);

export default termsPage;